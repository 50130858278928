import { useAuth0 } from '@auth0/auth0-react'
import { useRouter } from 'next/router'
import { parseCookies } from '@upvestcz/common/cookies'
import React, { useEffect } from 'react'
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Button,
    Flex,
} from '@upvestcz/shared-components'

function Index() {
    const { isLoading, loginWithRedirect } = useAuth0()

    const router = useRouter()

    const hasError = router.query.hasError === 'true'
    const isUnauthorized = router.query.isUnauthorized === 'true'
    const isLogout = router.query.isLogout === 'true'
    const login = () =>
        loginWithRedirect(
            window.origin === 'http://localhost:3000' || router.query.saml === 'false'
                ? {}
                : { connection: process.env.NEXT_PUBLIC_KB_SAML_CONNECTION },
        )

    useEffect(() => {
        if (hasError || isLoading || isLogout || isUnauthorized) {
            return
        }

        login()
    })

    return (
        <Flex h="100vh" align="center" justify="center" direction="column">
            {(hasError || isLogout || isUnauthorized) && (
                <Button
                    onClick={login}
                    // Disable on the server as the `isLoading` is `false`
                    // on the server and `true` when the JS loads which
                    // causes an inconsistency between the server and the browser.
                    isDisabled={typeof window === 'undefined' || isLoading}
                >
                    Přihlásit se
                </Button>
            )}
            {hasError && (
                <Alert mt={4} mb={2} status="error" flexDirection="column" textAlign="center">
                    <AlertIcon />
                    <AlertTitle mr={2}>Chyba při přihlašování</AlertTitle>
                    <AlertDescription>
                        Při vytváření přihlašování došlo k chybě. Zkuste to prosím znovu a kdyby
                        obtíže přetrvávaly, kontaktuje prosím podporu Upvest na kb@upvest.cz.
                    </AlertDescription>
                </Alert>
            )}
            {isUnauthorized && (
                <Alert mt={4} mb={2} status="error" flexDirection="column" textAlign="center">
                    <AlertIcon />
                    <AlertTitle mr={2}>Nemáte oprávnění k přístupu</AlertTitle>
                    <AlertDescription>
                        Bohužel nemáte dostatečná oprávnění k přístupu do aplikace. Pakliže si
                        myslíte, že by tak tomu nemělo být, kontaktujte podporu Upvest na
                        kb@upvest.cz.
                    </AlertDescription>
                </Alert>
            )}
        </Flex>
    )
}

export function getServerSideProps(ctx) {
    const isLoggedIn = !!parseCookies(ctx).id_token

    if (isLoggedIn) {
        return {
            redirect: {
                destination: '/dashboard',
                permanent: false,
            },
        }
    }

    return { props: {} }
}

Index.shouldDisableAuth = true

export default Index
